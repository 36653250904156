<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            3. Обязанности руководителя при возникновении пожара
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-img
              absolute
              lazy-src="img/material/chief.svg"
              max-height="100%"
              max-width="80%"
              contain
              src="img/material/chief.svg"
            >
            </v-img>
          </v-card>
        </v-col>
          <v-col cols="8">
           <Hint></Hint>
            <v-tabs
              v-model="tab1"
              color="red"
              slider-color="white"
              class="mt-10 red"
              active-class="ptm-act-class"
            >
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-1-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-2-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-3-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-4-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-5-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-6-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-7-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
              <v-tab class="px-0">
                <v-card-title>
                  <v-icon size="50">mdi-numeric-8-box-multiple-outline</v-icon>
                </v-card-title>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab1">

              <v-tab-item >
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Продублировать сообщение о пожаре по телефону 101 или 112, поставить в известность руководство и дежурные службы
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/call.svg"
                      max-height="100%"
                      max-width="15%"
                      contain
                      src="img/material/call.svg"
                    >
                    </v-img>
                </div>

              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  В случае угрозы жизни людей организовать их спасение, используя имеющиеся силы и средства
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/exit-2.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/exit-2.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Проверить включение в работу автоматических систем пожарной сигнализации и пожаротушения
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/sprinkler.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/sprinkler.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  При необходимости отключить электроэнергию (за исключением систем противопожарной защиты), остановить работу систем вентиляции в аварийном и смежном с ним помещениях
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/high-voltage.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/high-voltage.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Прекратить все работы в здании, кроме работ по ликвидации пожара
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/hand.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/hand.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Удалить за пределы опасной зоны сотрудников, не участвующих в тушении. Эвакуацию производить в соответствии с планами и инструкциями по эвакуации
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/evacuation-plan.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/evacuation-plan.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Осуществить общее руководство по тушению пожара до прибытия пожарно-спасательных подразделений. Обеспечить соблюдение требований безопасности сотрудниками, участвующими в тушении пожара.
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/fire-extinguisher-2.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/fire-extinguisher-2.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

              <v-tab-item>
                <v-card-text class="title font-weight-light mt-10 mb-10">
                  Организовать эвакуацию и защиту материальных ценностей
                </v-card-text>
                <div class="d-flex justify-center">
                  <v-img
                      lazy-src="img/material/locker.svg"
                      max-height="100%"
                      max-width="20%"
                      contain
                      src="img/material/locker.svg"
                    >
                    </v-img>
                </div>
              </v-tab-item>

            </v-tabs-items>
            <v-spacer></v-spacer>
          </v-col>

      </v-row>

    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab1: null
  }),
  components: {
    Hint
  }
}
</script>
